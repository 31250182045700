import React from 'react';
import styled from 'styled-components';

interface IStandardLayout {
    children: React.ReactNode;
}

const StandardLayout: React.FunctionComponent<IStandardLayout> = ({ children, ...rest }) => {
    return (
        <StandardLayoutStyled id="pb_layout">
            <MainStyled id="pb_main">{children}</MainStyled>
        </StandardLayoutStyled>
    );
};

const StandardLayoutStyled = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    min-height: 100%;
`;

const MainStyled = styled.main`
    z-index: 1;
    flex: 1;
    display: flex;
    flex-direction: column;
    -webkit-overflow-scrolling: touch;
    position: relative;
    height: 100%;
`;

export default StandardLayout;
