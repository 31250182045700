import { showAccountPanel } from '@internal/state/accountPanel';
import { Avatar } from '@internal/ui/components/Avatar';
import { twButtonTransition } from '@internal/ui/components/Button.styles';
import { Tooltip } from '@internal/ui/components/Tooltip';
import classNames from 'classnames';
import dynamic from 'next/dynamic';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import OverlayPortal from './OverlayPortal';

const DynamicAccountPanel = dynamic(() => import('features/account-panel'), { ssr: false });

interface AccountButtonProps {
    onClick?: () => void;
    includeAccountPanel?: boolean;
}

export const AccountButton = (props: AccountButtonProps) => {
    const { onClick, includeAccountPanel = true } = props;

    const initialized = useAppSelector((state) => state.user.initialized);
    const userName = useAppSelector((state) => state.user.name);
    const avatarUrl = useAppSelector((state) => state.user.avatar?.url);
    const loggedIn = useAppSelector((state) => !!state.user.id);
    const dispatch = useAppDispatch();

    if (!initialized || !loggedIn) {
        return null;
    }

    return (
        <>
            <Tooltip label="Open account settings" offset={12}>
                <button
                    className={classNames(
                        twButtonTransition(),
                        'rounded-full shadow-avatar transition-all hover:shadow-[0px_0px_0px_2px] hover:shadow-primary-200 focus-visible:shadow-focusVisible'
                    )}
                    onClick={() => {
                        dispatch(showAccountPanel());
                        onClick?.();
                    }}
                >
                    <Avatar height={48} width={48} aria-label={userName} src={avatarUrl} />
                </button>
            </Tooltip>

            {includeAccountPanel ? (
                <OverlayPortal>
                    <DynamicAccountPanel />
                </OverlayPortal>
            ) : null}
        </>
    );
};
