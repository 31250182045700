import { RoomStates } from '@internal/state/room';
import classNames from 'classnames';
import { ReactNode } from 'react';

const copy = {
    [RoomStates.LoadingRoom]: 'Spinning up the room 🚀',
    [RoomStates.Loading]: 'Loading',
    [RoomStates.Reconnecting]: 'Reconnecting 🔄',
    [RoomStates.Connecting]: 'Connecting to room',
    [RoomStates.VideoLoading]: 'Loading video',
    [RoomStates.VideoPending]: 'Waiting for stream',
    [RoomStates.SubscribeError]: 'Unable to load stream',
    [RoomStates.RoomNotFound]: 'Hmm, that room doesn’t seem to exist.',
    [RoomStates.OpenInBrowser]: 'Open in browser to join as a guest',
    [RoomStates.Disconnected]: 'Looks like you got disconnected. Refresh to rejoin.',
    [RoomStates.Queued]: 'Waiting to join',
    [RoomStates.Error]:
        'Looks like something’s not working quite right. Hopefully a quick reload will fix it.',
};

interface RoomStateProps {
    className?: string;
    state?: RoomStates;
    children?: ReactNode;
    onClick?: () => void;
}

export const RoomState = (props: RoomStateProps) => {
    const { className, state, children = '', onClick } = props;

    return (
        <div className={classNames('relative flex-col flex-center', className)}>
            <div
                className="m-5 text-center text-primary heading3 flex-center sm:m-10"
                onClick={onClick}
            >
                {children || copy[state]}
            </div>
        </div>
    );
};
