import React from 'react';
import styled, { css } from 'styled-components';
import { rem } from 'polished';
import { Breakpoint } from '@internal/ui/css/breakpoints';
import { fade } from '@internal/ui/css/fade';
import { Base } from '@internal/ui/palette/base';
import { ReactNode } from 'react';

interface AppNavProps {
    className?: string;
    children?: ReactNode;
}

const AppNav = (props: AppNavProps) => {
    const { className, children } = props;

    return <AppNavStyled className={className}>{children}</AppNavStyled>;
};
const AppNavStyled = styled.div`
    height: ${rem(72)};
    box-sizing: border-box;
    flex-shrink: 0;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    ${fade({ to: 'bottom', color: Base.Tertiary200 })}
    z-index: 3;
    padding: 0 ${rem(12)};

    @media screen and (min-width: ${rem(Breakpoint.Small)}) {
        z-index: 2;
    }
`;

export default AppNav;
