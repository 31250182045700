function _taggedTemplateLiteral(strings, raw) {
    if (!raw) {
        raw = strings.slice(0);
    }
    return Object.freeze(Object.defineProperties(strings, {
        raw: {
            value: Object.freeze(raw)
        }
    }));
}
function _templateObject() {
    var data = _taggedTemplateLiteral([
        "\n    0% {\n        opacity: 0;\n        transform: scale(0.94);\n        visibility: visible;\n    }\n    100% {\n        opacity: 1;\n        transform: scale(1);\n    }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _taggedTemplateLiteral([
        "\n    0% {\n        opacity: 1;\n        transform: scale(1);\n    }\n    100% {\n        opacity: 0;\n        transform: scale(0.94);\n        visibility: hidden;\n    }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _taggedTemplateLiteral([
        "\n    transform-origin: center;\n    animation-duration: ",
        "ms;\n    animation-timing-function: ease;\n    animation-name: ",
        ";\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _taggedTemplateLiteral([
        "\n    0% {\n        opacity: 0;\n        visibility: visible;\n    }\n    100% {\n        opacity: 1;\n    }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _taggedTemplateLiteral([
        "\n    0% {\n        opacity: 1;\n    }\n    100% {\n        opacity: 0;\n        visibility: hidden;\n    }\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _taggedTemplateLiteral([
        "\n    animation-duration: ",
        "ms;\n    animation-timing-function: ease;\n    animation-name: ",
        ";\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
function _templateObject6() {
    var data = _taggedTemplateLiteral([
        "\n    100% {\n      transform: translateX(100%);\n    }\n"
    ]);
    _templateObject6 = function _templateObject6() {
        return data;
    };
    return data;
}
function _templateObject7() {
    var data = _taggedTemplateLiteral([
        "\n    position: relative;\n    background-color: #393838;\n    overflow: hidden;\n\n    &::after {\n        position: absolute;\n        top: 0;\n        right: -100%;\n        bottom: 0;\n        left: -100%;\n        transform: translateX(-100%);\n        ",
        "\n        animation: ",
        " 2s infinite;\n        content: '';\n    }\n"
    ]);
    _templateObject7 = function _templateObject7() {
        return data;
    };
    return data;
}
import { css, keyframes } from "styled-components";
import { fadeInOut } from "./fade";
export var AnimateInPopover = keyframes(_templateObject());
export var AnimateOutPopover = keyframes(_templateObject1());
export var POPOVER_TRANSITION_DURATION = 200;
export var popoverTransition = css(_templateObject2(), POPOVER_TRANSITION_DURATION, function(param) {
    var visible = param.visible;
    return visible ? AnimateInPopover : AnimateOutPopover;
});
export var AnimateInSelect = keyframes(_templateObject3());
export var AnimateOutSelect = keyframes(_templateObject4());
export var SELECT_TRANSITION_DURATION = 200;
export var selectTransition = css(_templateObject5(), SELECT_TRANSITION_DURATION, function(param) {
    var visible = param.visible;
    return visible ? AnimateInSelect : AnimateOutSelect;
});
export var AnimateShimmer = keyframes(_templateObject6());
export var loadingShimmer = css(_templateObject7(), fadeInOut({
    to: "right",
    color: "#575757"
}), AnimateShimmer);
