import { RoomStates } from '@internal/state/room';
import { TrackingContext } from '@internal/tracking/types';
import { Button } from '@internal/ui/components/Button';
import { Sticky } from '@internal/ui/css/Sticky';
import { AccountButton } from 'components/AccountButton';
import NavLoggedIn from 'components/NavLoggedIn';
import SEO from 'components/SEO';
import useAuth from 'features/auth/useAuth';
import { RoomState } from 'pages/[room]/components/RoomState';
import { AppComponent } from 'typescript/typings';
import Gif401 from '../../public/gif/mutombo.gif';
import Gif404 from '../../public/gif/kawhi.gif';
import Gif500 from '../../public/gif/jokic.gif';
import Image from 'next/image';
import { useState } from 'react';

interface PageError {
    code: number;
}

const gifs = {
    [401]: Gif401,
    [404]: Gif404,
    [500]: Gif500,
};

const gifAlt = {
    [401]: 'GIF of Dikembe Mutombo wagging his finger',
    [404]: 'GIF of Kawhi Leonard looking for something',
    [500]: 'GIF of Nikola Jokic looking confused',
};

const PageError: AppComponent<PageError> = ({ code }) => {
    const { openLogin } = useAuth();

    const [gifAspectRatio, setGifAspectRatio] = useState(1);

    const getErrorCopy = () => {
        if (code === 400) {
            return 'Hm... looks like that’s not a valid page. Are you sure that’s the right link?';
        }

        if (code === 401) {
            return 'Hmm... looks like you’re not authorized to view this page.';
        }

        if (code === 404) {
            return 'Hmm... we can’t find that page. Are you sure that’s the right link?';
        }

        // Fallback to `RoomState` default
        return undefined;
    };

    const getPageTitle = () => {
        if (code === 400) {
            return 'Invalid page';
        }

        if (code === 401) {
            return 'Unauthorized';
        }

        if (code === 404) {
            return 'Page not found';
        }

        return 'Something went wrong';
    };

    return (
        <div className="flex min-h-full w-full flex-1 flex-col items-stretch">
            <Sticky sticky="top">
                <NavLoggedIn className="transition-all [&.stuck]:bg-tertiary-100 [&.stuck]:shadow-popover">
                    <AccountButton />
                </NavLoggedIn>
            </Sticky>
            <SEO title={getPageTitle()} noindex />
            <div className="flex-1 flex-col p-5 pt-0 flex-center">
                <RoomState
                    className="max-w-[600px]"
                    state={code === 500 ? RoomStates.Error : RoomStates.RoomNotFound}
                >
                    {getErrorCopy()}
                </RoomState>
                <Image
                    src={gifs[code ?? 500]}
                    width={280}
                    height={280 / gifAspectRatio}
                    className="rounded-xl"
                    alt={gifAlt[code ?? 500]}
                    onLoadingComplete={({ naturalWidth, naturalHeight }) => {
                        setGifAspectRatio(naturalWidth / naturalHeight);
                    }}
                />
                {code === 401 ? (
                    <Button
                        className="mt-8"
                        variant="secondary"
                        text="Log in"
                        onClick={() => {
                            openLogin(TrackingContext.Error401);
                        }}
                    />
                ) : null}
            </div>
        </div>
    );
};

export default PageError;
